import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import * as Api from 'src/api'
import { CourseCompletedIcon } from 'src/assets/icons/customIcons/course-icons/CourseCompleted'
import { CourseFailedIcon } from 'src/assets/icons/customIcons/course-icons/CourseFailed'
import CoursePending from 'src/assets/icons/customIcons/course-icons/CoursePending'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { useTheme } from 'src/state/providers/Theme'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import BackButton from 'src/views/components/BackButton'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import { PaginationBottom, PaginationTop } from 'src/views/components/Pagination'
import UserPhoto from 'src/views/components/UserPhoto'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerPortfolioSectionWorksPage(): JSX.Element | null {
  const t = useTranslatable()
  return (
    <>
      <Helmet title={t('portfolio:portfolio')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const headers = useAuthenticatedHeaders()
  const { id } = useParams()
  const locale = useLocale()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams({
    page: '1',
  })
  const theme = useTheme()
  const section = useApi({
    endpoint: Api.getLecturerPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
      }),
      [headers, id]
    ),
  })

  const breadcrumbsItems = [
    { page: `${t('portfolio:portfolio')}`, path: `/${locale}/lecturer/portfolio/sections` },
    { page: `${section.data.name}`, path: pathname },
  ]

  const page = searchParams.get('page')
  const perPage = searchParams.get('perPage') ?? '10'

  const [pathValue, setPathValue] = React.useState('')

  React.useEffect(() => {
    const segments = pathname.split('/').filter(Boolean)
    const lastPathSegment = segments.pop() || ''

    setPathValue(lastPathSegment)
  }, [pathname])

  const getStatusValue = React.useCallback(() => {
    if (pathValue === 'evaluated') return 1
    if (pathValue === 'rejected') return 2
    return 0
  }, [pathValue])

  const { data: works, isValidating: worksPending } = useApi({
    endpoint: Api.getLecturerPortfolioSectionWorks,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: id!,
        },
        query: {
          page: parseInt(page!),
          perPage: parseInt(perPage),
          'filters[status]': getStatusValue() || 0,
        },
      }),
      [getStatusValue, headers, id, page, perPage]
    ),
    suspense: false,
  })

  return (
    <Section
      title={`${t('portfolio:portfolio')} - ${section.data.name}`}
      icon={<Portfolio />}
      breadcrubms={breadcrumbsItems}
      rightElement={<BackButton link={`/${locale}/lecturer/portfolio/sections`} />}
    >
      {works?.data != null && works?.data.length > 0 ? (
        <>
          <PaginationTop pagination={works.meta.pagination} />
          <div className="w-full py-3">
            <Table.Table className="mt-4">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th />
                  <Table.Th>{t('portfolio:assignment_id')}</Table.Th>
                  <Table.Th>{t('portfolio:competence')}</Table.Th>
                  <Table.Th>{t('student:student')}</Table.Th>
                  <Table.Th>{t('common:status')}</Table.Th>
                  <Table.Th>{t('common:date')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {works?.data.map((work) => (
                  <Table.Tr key={work.id}>
                    <Table.Td>
                      <div>
                        {work.status === 'STATUS_PENDING' && (
                          <div data-tooltip-id={`${work.id}-statusTooltip`}>
                            <CoursePending />
                          </div>
                        )}
                        {work.status === 'STATUS_EVALUATED' ? (
                          <div data-tooltip-id={`${work.id}-statusTooltip`}>
                            <CourseCompletedIcon />{' '}
                          </div>
                        ) : null}
                        {work.status === 'STATUS_REJECTED' && (
                          <div data-tooltip-id={`${work.id}-statusTooltip`}>
                            <CourseFailedIcon />
                          </div>
                        )}
                        <Tooltip
                          id={`${work.id}-statusTooltip`}
                          place="top"
                          variant={theme === 'dark' ? 'dark' : 'light'}
                          className="mb-1 p-1"
                        >
                          <div className="mb-0">
                            {work.status === 'STATUS_PENDING' && t('portfolio:status_pending')}
                            {work.status === 'STATUS_EVALUATED' && t('portfolio:status_evaluated')}
                            {work.status === 'STATUS_REJECTED' && t('portfolio:status_rejected')}
                          </div>
                        </Tooltip>
                      </div>
                    </Table.Td>

                    <Table.Td className="!max-w-[250px]">
                      <Link to={`/${locale}/lecturer/portfolio/sections/${section.data.id}/works/${work.id}`}>
                        <span>{work.id}</span>
                      </Link>
                    </Table.Td>

                    <Table.Td className="!max-w-[150px]">
                      {work.competencies?.slice(0, 2).map((competency, index, array) => (
                        <span key={competency.id}>
                          {(index !== 0 ? ', ' : '') + competency.name}
                          {index === array.length - 1 ? '...' : ''}
                        </span>
                      ))}
                    </Table.Td>
                    <Table.Td className="whitespace-nowrap pr-4 align-middle">
                      <UserPhoto className="scale-[0.7]" user={work.student} />{' '}
                      <Link
                        to={`/${locale}/users/${work.student?.uid}`}
                        className="ml-2 whitespace-nowrap align-middle hover:underline"
                        data-tooltip-id={`${work.id}-lecturer`}
                      >
                        <span className="ml-2">{work.student?.fullName}</span>
                      </Link>
                    </Table.Td>
                    <Table.Td className="px-3 align-middle">
                      <div>
                        {work.status === 'STATUS_PENDING' && <div>{t('portfolio:status_pending')}</div>}
                        {work.status === 'STATUS_EVALUATED' ? <div>{t('portfolio:status_evaluated')}</div> : null}
                        {work.status === 'STATUS_REJECTED' && <div>{t('portfolio:status_rejected')}</div>}
                        {work.status === 'STATUS_DRAFT' && <div>{t('portfolio:status_draft')}</div>}
                      </div>
                    </Table.Td>
                    <Table.Td>{work.createdAt}</Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
          </div>
          <PaginationBottom pagination={works!.meta.pagination} />
        </>
      ) : worksPending ? (
        <Loader className="flex" style={{ margin: 'auto' }} />
      ) : (
        <NoContent header={t('error:records_not_found')} image={Warning} />
      )}
    </Section>
  )
}
