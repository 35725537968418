import { FieldArray, useFormikContext } from 'formik'
import * as React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'

import { Button } from 'src/tailwind/components/Button'
import * as Table from 'src/tailwind/components/Table'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextInput from 'src/views/components/forms/formik/TextInput'
import Loader from 'src/views/components/Loader'
import NoContent from 'src/views/components/NoContent'
import PopoverComponent from 'src/views/components/PopoverComponent'
import Select from 'src/views/components/Select'

interface Props {
  groupsPaper: null | Api.Paper
  groupsPaperPending: boolean
  selectedGroups: string[]
  refetchPaperData: () => Promise<void>
}

export default function PaperTable({
  groupsPaper,
  groupsPaperPending,
  selectedGroups,
  refetchPaperData,
}: Props): React.ReactElement {
  const [checkedCriterions, setCheckedCriterions] = React.useState<string[]>([])
  const [criterions, setCriterions] = React.useState<Array<Api.PaperCriteria | Api.CoursePaperCriteria>>([])
  const [showForm, setShowForm] = React.useState(true)
  const [searchParams] = useSearchParams()
  const groupId = searchParams.get('groupId')
  const editMultiple = searchParams.get('editMultiple')
  const headers = useAuthenticatedHeaders()

  const { data: paperCriterions, isValidating: paperCriterionsPending } = useApi({
    endpoint: Api.getPaperCriterias,
    params: React.useMemo(
      () => ({
        headers,
      }),
      [headers]
    ),
  })
  const t = useTranslatable()
  const canEdit =
    (groupId != null || (editMultiple != null && selectedGroups.length > 0)) && groupsPaper?.manage?.canEdit !== false

  React.useEffect(() => {
    if (!showForm) {
      setShowForm(true)
    }
  }, [showForm])

  React.useEffect(() => {
    if (groupsPaper?.criterions != null) {
      setCheckedCriterions(groupsPaper.criterions.map((paper) => paper.id))
      setCriterions([...groupsPaper.criterions])
    } else if (paperCriterions != null) {
      setCriterions([...paperCriterions.filter((criterion) => criterion.id === '5' || criterion.id === '6')])
    }
    setShowForm(false)
  }, [groupsPaper, groupId, paperCriterions])

  if (paperCriterionsPending || groupsPaperPending) {
    return <Loader className="m-auto flex" />
  } else if (paperCriterions == null) {
    return <NoContent header={t('common:records_not_found')} image={Warning} marginTop="5" />
  }

  return (
    <div className="w-full">
      {canEdit && showForm ? (
        <PaperForm
          criterions={criterions as Array<Api.PaperCriteria & Api.CoursePaperCriteria>}
          checkedCriterions={checkedCriterions}
          setCheckedCriterions={setCheckedCriterions}
          groupsPaper={groupsPaper}
          paperCriterions={paperCriterions}
          selectedGroups={selectedGroups}
          refetchPaperData={refetchPaperData}
        />
      ) : (
        <div className="my-3 rounded-[4px] bg-black px-4 py-3 font-bold text-white">
          {editMultiple != null ? t('error:choose_groups') : t('error:you_can_not_register_this_paper')}
        </div>
      )}
    </div>
  )
}

interface PaperFormProps {
  readonly criterions: Array<Api.PaperCriteria & Api.CoursePaperCriteria>
  readonly checkedCriterions: string[]
  readonly setCheckedCriterions: React.Dispatch<React.SetStateAction<string[]>>
  readonly groupsPaper: Api.Paper | null
  readonly paperCriterions: Api.getPaperCriteriasOk
  readonly selectedGroups: string[]
  readonly refetchPaperData: () => Promise<void>
}
interface IPaperTriteria {
  criteriaId: string
  min: number
  max: number
}
interface FormikValues {
  intermediateScoreLimit: number
  criterias: IPaperTriteria[]
}

function PaperForm({
  criterions,
  checkedCriterions,
  setCheckedCriterions,
  groupsPaper,
  paperCriterions,
  selectedGroups,
  refetchPaperData,
}: PaperFormProps): React.ReactElement {
  const t = useTranslatable()
  const [searchParams, setSearchParams] = useSearchParams()
  const headers = useAuthenticatedHeaders()
  const groupId = searchParams.get('groupId')
  const editMultiple = searchParams.get('editMultiple')

  const { courseId } = useParams()
  const [submitedSuccessfully, setSubmitedSuccessfully] = React.useState(false)

  const initialValues: FormikValues = {
    intermediateScoreLimit: groupsPaper?.intermediateScoreLimit ?? 0,
    criterias: criterions.map((criterion) => ({
      criteriaId: criterion.id,
      min: criterion.min ?? 0,
      max: criterion.max ?? 0,
    })),
  }

  const onSubmit = React.useCallback(
    async (values: FormikValues): Promise<void> => {
      const filteredValues: IPaperTriteria[] = values.criterias.filter((criteria) => {
        return (
          checkedCriterions.includes(criteria.criteriaId) || criteria.criteriaId === '5' || criteria.criteriaId === '6'
        )
      })

      if (groupsPaper?.id != null) {
        await Api.updatePaperCriterias({
          headers,
          args: {
            courseId: courseId!,
            groupId: groupId!,
            paperId: groupsPaper.id,
          },
          body: {
            intermediateScoreLimit: values.intermediateScoreLimit,
            criterias: filteredValues,
          },
        })

        toast.success(t('uncategorized:paper_successfully_updated'))
        setSubmitedSuccessfully(true)
      } else {
        await Api.postPaperCriterias({
          headers,
          args: {
            courseId: courseId!,
          },
          body: {
            intermediateScoreLimit: values.intermediateScoreLimit,
            criterias: filteredValues,
            groups: editMultiple != null ? selectedGroups : [groupId!],
          },
        })
        setSubmitedSuccessfully(true)
        toast.success(t('uncategorized:paper_successfully_added'))
      }
    },
    [groupsPaper?.id, checkedCriterions, headers, courseId, groupId, t, editMultiple, selectedGroups]
  )
  React.useEffect(() => {
    void (async () => {
      if (submitedSuccessfully) {
        await refetchPaperData()
        editMultiple != null ? searchParams.delete('editMultiple') : searchParams.delete('edit')
        setSearchParams(searchParams)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitedSuccessfully])
  return (
    <Form initialValues={initialValues} onSubmit={onSubmit} isConfirmable={!submitedSuccessfully}>
      <FormError />
      <FormContent
        checkedCriterions={checkedCriterions}
        setCheckedCriterions={setCheckedCriterions}
        paperCriterions={paperCriterions}
      />
      <div className="mt-2 flex w-full justify-end">
        <Button variant="blue" type="submit">
          {t('common:save')}
        </Button>
      </div>
    </Form>
  )
}

interface FormContentProps {
  readonly checkedCriterions: string[]
  readonly setCheckedCriterions: React.Dispatch<React.SetStateAction<string[]>>
  readonly paperCriterions: Api.getPaperCriteriasOk
}

function FormContent({
  checkedCriterions,
  setCheckedCriterions,
  paperCriterions,
}: FormContentProps): React.ReactElement | null {
  const t = useTranslatable()
  const formik = useFormikContext<FormikValues>()

  React.useEffect(() => {
    void formik.setFieldValue(
      `criterias.${formik.values.criterias.findIndex(({ criteriaId }) => criteriaId === '6')}.max`,
      formik.values.criterias.find(({ criteriaId }) => criteriaId === '5')?.max
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.criterias.find(({ criteriaId }) => criteriaId === '5')?.max])
  React.useEffect(() => {
    void formik.setFieldValue(
      `criterias.${formik.values.criterias.findIndex(({ criteriaId }) => criteriaId === '6')}.min`,
      formik.values.criterias.find(({ criteriaId }) => criteriaId === '5')?.min
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.criterias.find(({ criteriaId }) => criteriaId === '5')?.min])

  const criterionOptions = React.useMemo(() => {
    return paperCriterions?.filter(
      (criterion) => !formik.values.criterias.some(({ criteriaId }) => criteriaId === criterion.id)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.criterias?.length, paperCriterions])

  if (paperCriterions == null) return null

  return (
    <FieldArray name="criterias">
      {(arrayHelpers: {
        form: {
          values: FormikValues
        }
      }) => {
        const { form } = arrayHelpers
        return (
          <>
            <label htmlFor="criterions" className="text-primaryTextColor">
              <span className="font-bold">
                {t('course:select_criteria_from_the_list_and_write_point_relevant_to_criteria')}
              </span>
              <Select
                className="my-3"
                id="criterions"
                placeholder={t('course:select_criteria')}
                options={criterionOptions.map((criterion) => ({ value: criterion.id, label: criterion.name }))}
                onChange={(option) => {
                  setCheckedCriterions([...checkedCriterions, option!.value])

                  const existingCriteriaIndex = form.values.criterias.findIndex(
                    ({ criteriaId }) => criteriaId === option!.value
                  )

                  if (existingCriteriaIndex === -1) {
                    const insertIndex = form.values.criterias.findIndex(({ criteriaId }) => option!.value < criteriaId)

                    // insert checked criteria at the calculated index
                    form.values.criterias.splice(insertIndex !== -1 ? insertIndex : form.values.criterias.length, 0, {
                      criteriaId: option!.value,
                      max: 0,
                      min: 0,
                    })
                  }
                }}
              />
            </label>
            <Table.Table className="mt-6">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th scope="col" />
                </Table.Tr>
                <Table.Tr>
                  <Table.Th scope="col" />
                  <Table.Th scope="col" className="pl-0 text-start">
                    {t('course:assessment_components')}
                  </Table.Th>
                  <Table.Th scope="col" className="text-start">
                    {t('common:min')}
                  </Table.Th>
                  <Table.Th scope="col" className="whitespace-nowrap text-center">
                    {t('common:max')}
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                <Table.Tr>
                  <Table.Td />
                  <Table.Td>{t('course:intermediate_score_limit')} :</Table.Td>
                  <Table.Td>
                    <div className="w-[calc(100%-22px)]">
                      <TextInput name="intermediateScoreLimit" type="number" />
                    </div>
                  </Table.Td>
                </Table.Tr>
                {form.values.criterias.map(({ criteriaId }, key) => (
                  <Table.Tr key={criteriaId}>
                    <Table.Td className="rounded-l-lg pt-3 !align-baseline">
                      {criteriaId !== '5' && criteriaId !== '6' && (
                        <input
                          type="checkbox"
                          checked={checkedCriterions.includes(criteriaId)}
                          onChange={() => {
                            if (checkedCriterions.includes(criteriaId)) {
                              setCheckedCriterions(checkedCriterions.filter((id) => id !== criteriaId))
                            } else {
                              setCheckedCriterions([...checkedCriterions, criteriaId])
                            }
                          }}
                        />
                      )}
                    </Table.Td>
                    <Table.Td className="pt-3 !align-baseline">
                      <div>{paperCriterions.find((criterion) => criterion.id === criteriaId)?.name}</div>
                    </Table.Td>
                    <Table.Td className="!align-baseline">
                      <div className="item-center flex">
                        <TextInput
                          name={`criterias.${key}.min`}
                          type="number"
                          disabled={
                            criteriaId === '6' || (!checkedCriterions.includes(criteriaId) && criteriaId !== '5')
                          }
                          required
                        />
                        <div className="item-center mb-3 flex">
                          <PopoverComponent>
                            <span className="flex flex-col">{t('course:minimum_value')}</span>
                          </PopoverComponent>
                        </div>
                      </div>
                    </Table.Td>
                    <Table.Td className="rounded-r-lg !align-baseline">
                      <div className="item-center flex">
                        <TextInput
                          name={`criterias.${key}.max`}
                          type="number"
                          disabled={
                            criteriaId === '6' || (!checkedCriterions.includes(criteriaId) && criteriaId !== '5')
                          }
                          required
                        />
                        <div className="item-center mb-3 flex">
                          <PopoverComponent>
                            <span className="flex flex-col">{t('course:maxsimum_value')}</span>
                          </PopoverComponent>
                        </div>
                      </div>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
          </>
        )
      }}
    </FieldArray>
  )
}
